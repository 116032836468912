import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { MdlAddTransportComponent } from "../mdl-add-transport/mdl-add-transport.component";
import { MdladdDriverComponent } from "../mdl-add-driver/mdl-add-driver.component";
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {  Router,ActivatedRoute } from "@angular/router";
import { catchError, switchMap } from 'rxjs/operators';
import { MdlimageComponent } from "../mdl-image/mdl-image.component";

/* import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'; */


@Component({
  selector: 'app-route-assign',
  templateUrl: './route-assign.component.html',
  styleUrls: ['./route-assign.component.scss']
})
export class RouteAssignComponent implements OnInit {
  public loading: boolean = true;
  public loader: boolean = false;
  
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  theitems = [];
  public id: string = '';
  public groutes:any=[];
  public routeCode:string='';
  public seller:string='';
  public order:any=[];
  public myorders:any = [];
  selectedCity: string | null = null;
  public cities:any = [];
  public routeDate: Date;
  public TranspCode: string = '';
  public Driver: string = '';
  public assign: string = '';
  public idDelivery: number = 0;
  public statusTransport: number = null;
  public status: string = '';
  public dropdownOpen: boolean = false;
  public dropdownSellerOpen: boolean = false;
  retiraCliente: boolean = false;
  retiraVendedor: boolean = false;

  
  orderSelections: { [orderCode: string]: boolean } = {};
  orderData: any[] = [];
  backupOrderData: any[] = [];

  public transports:any =[];
  public drivers:any =[];


  backupOrders: any = [];
  filterText: string = '';

  public sellers = [];
  public sellerUsers = [];

  
  public request:any = {
    OrderCode: 0,
    CardCode: "",
    CardName: "",
    BillTo: "",
    ShipTo: "",
    Lat: "",
    Lng: "",
    Comments: "",
    ListNum: 0,
    DiscountRate: 0,
    SlpCode: -1,
    RouteID: 0,
    Status: 0,
    Details: []
  }
  

  selectedSellers: string[] = [];
  selectedRoutes: string[] = [];


  constructor(private api: ApiService, private modalService: NgbModal,  public router: Router,  public ar: ActivatedRoute) { 
    this.id = this.ar.snapshot.paramMap.get("id") || '';  this.status = this.ar.snapshot.queryParamMap.get("Status") || ''; }

  ngOnInit(): void {
    if (this.id != '') {
      console.log(this.status)
      this.loading = true;
      this.preloadOrder();
    } 
    this.api.getAllRoutes({
      routecode:''
    }).subscribe((data:any)=>{
      this.groutes = data;
      this.groutes = this.sortRoutesByName(this.groutes);
      
    })
    this.api.getUsers().subscribe((d:any) => {
      for (let i = 0; i < d.length; i++) {
        if (d[i].UserType=="V") {
          this.sellers.push(d[i]);
        }
      }
    });

    // IF IT DOESN'T BRAKE ANY SECTION IN THE CODE, REPLACE THE 'sellerUsers' FOR 'sellers' FOR THE MOMENT THIS IS FOR TESTING
    this.api.getUserSellers().subscribe((d:any) =>{
      for (let i = 0; i < d.length; i++) {
        if (d[i].UserType=="V") {
          this.sellerUsers.push(d[i]);
        }
      }
      this.sellerUsers = this.sortSellersByName(this.sellerUsers);
    });
    
    this.api.getCarriers().subscribe((data:any)=>{
      this.drivers = data;
    })

    this.api.getTransports().subscribe((data:any)=>{
      this.transports = data;
    })
    /* this.dropdownList = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }; */
  }

  isSelectedRoute(routeCode: string): boolean {
    return this.selectedRoutes.includes(routeCode);
  }

  isSelectedSeller(sellerCode: string): boolean {
    return this.selectedSellers.includes(sellerCode);
  }

  selectRoutes(event: any): void {
    const selectedRoute = event.target.value;
    if (event.pointerId===1){
      return
    } 
    if(selectedRoute===""){
      this.selectedRoutes=[];
      this.selectRoute();
      return;
    }
    if (this.isSelectedRoute(selectedRoute)) {
        this.selectedRoutes = this.selectedRoutes.filter(route => route !== selectedRoute);
        if(this.selectedRoutes.length==0){
          this.routeCode="";
        }
    } else {
        this.selectedRoutes.push(selectedRoute);
    }
    if(this.selectedRoutes.length!==0){
      this.selectRoute();
    }
    else if(this.selectedRoutes.length==0 && this.selectedSellers.length!==0){
      this.selectRoute();
    }
    else if(this.selectedRoutes.length==0 && this.selectedSellers.length==0 ){
      this.myorders=[];
      this.cities=[];

   }
 }


  selectSeller(event: any): void {
    const selectedSeller = event.target.value;
    if (event.pointerId===1){
      return
    } 
    if(selectedSeller===""){
      this.selectedSellers=[];
      this.selectRoute();
      return;
    }
    if (this.isSelectedSeller(selectedSeller)) {
      this.selectedSellers = this.selectedSellers.filter(route => route !== selectedSeller);
      if(this.selectedSellers.length==0){
        this.seller="";
      }
    } else {
        this.selectedSellers.push(selectedSeller);
    }
    
    if(this.selectedSellers.length!==0){
      this.selectRoute();
    }

    else if(this.selectedRoutes.length!==0 && this.selectedSellers.length==0){
      this.selectRoute();
    }
    else if(this.selectedRoutes.length==0 && this.selectedSellers.length==0 ){
      this.myorders=[];
      this.cities=[];

   }
  
  }



  
  async preloadOrder() {
    this.api.showLoading();
    this.api.getAssignedList(this.id).subscribe(async (data: any) => {
      console.log(data)
      this.routeDate = data[0].AssignDate.split(' ')[0];
      if (data[0].VehicleCode && data[0].DriverCode){
        this.TranspCode = data[0].VehicleCode;
        this.Driver = data[0].DriverCode;}
      if (data[0].Status=="23"){
        this.retiraVendedor=true;
        this.statusTransport=23
      }
      if (data[0].Status=="24"){
        this.retiraCliente=true;
        this.statusTransport=24
      }
  
      await this.api.getOrdersByPackingId({ PackingId: this.id }).subscribe(async (orderData: any) => {
        this.orderData = orderData;
        this.backupOrderData = JSON.parse(JSON.stringify(orderData));
        for (const order of orderData) {
          this.orderSelections[order.OrderCode] = true;
          this.loading = false;
        }
      });
  
      this.api.hideLoading();
    });
  }
   
  areAllSubArraysEmpty(): boolean {
    return this.myorders.every(arr => arr.length === 0);
  }


  toggleCollapse(cityGroup: any) {
    if (cityGroup.expanded) {
      cityGroup.expanded = false;
    } else {
      this.cities.forEach((group) => (group.expanded = false));
      cityGroup.expanded = true;
/*       const flattenedOrders = this.backupOrders.reduce((acc, val) => acc.concat(val), []);
      this.myorders = [flattenedOrders.filter((row) => row.City === cityGroup.city)]; */
    
      /* this.rebuildMyOrders(); */
    }
  }

  
 /*  applyFilter() {
    if (!this.filterText) {
      this.myorders = [...this.backupOrders];
      return;
    }
    const flattenedOrders = this.backupOrders.reduce((acc, val) => acc.concat(val), []);
    this.myorders = [
      flattenedOrders.filter(row =>
        row.DocEntry.toString().toLowerCase().includes(this.filterText) ||
        row.CardCode.toString().toLowerCase().includes(this.filterText) ||
        row.CardCode.toString().toUpperCase().includes(this.filterText) ||
        row.CardName.toLowerCase().includes(this.filterText) ||
        row.CardName.toUpperCase().includes(this.filterText)
      ),
    ];
    this.rebuildMyOrders();
  } */
  
  getFilteredRows(city: string): any[] {
    const allRows = this.backupOrders.flat();
    
    if (!this.filterText) {
      return allRows.filter(row => row.City === city);
    } else {
      return allRows.filter(row =>
        row.City === city &&
        (
          row.FolioNum.toString().toLowerCase().includes(this.filterText) ||
          row.CardCode.toString().toLowerCase().includes(this.filterText) ||
          row.CardCode.toString().toUpperCase().includes(this.filterText) ||
          row.CardName.toLowerCase().includes(this.filterText) ||
          row.CardName.toUpperCase().includes(this.filterText)
        )
      );
    }
  }
  
  
  
  async viewImage(OrderCode) {
    this.api.showLoading();
    let response=[]
    this.api.getOrderDetails({OrderCode:OrderCode.toString()}).subscribe((data:any)=>{
      if (data) {
        console.log(data)

        if (Array.isArray(data) && data.length > 0) {
          response = data
            .map(innerArray => innerArray.RejectPhoto)
            .filter(RejectPhoto => RejectPhoto !== null);
        }

       const modalRef = this.modalService.open(MdlimageComponent, {
          size: 'sm',
          keyboard: false,
          backdrop: 'static',
          windowClass: 'modal-1080',
        });
        modalRef.componentInstance.imagesUrl = response;
        modalRef.componentInstance.Packing_id = this.id; 
        this.api.hideLoading();
      } else {
        this.api.showToastError("Ha habido un error. Intente nuevamente");
      } 
    })
  
  }
 
  
  
rebuildMyOrders() {
  const length = this.myorders[0].length;
  const oneThirdLength = Math.ceil(length / 3);
  const twoThirdsLength = oneThirdLength * 2;

  this.myorders = [
    this.myorders[0].slice(0, oneThirdLength),
    this.myorders[0].slice(oneThirdLength, twoThirdsLength),
    this.myorders[0].slice(twoThirdsLength),
  ];
}

onCheckboxChange(option: any, event: any) {
  this.orderSelections[option.OrderCode] = event.target.checked;
  this.updateOrderData();
}

toggleRetiraVendedor() {
  if (this.retiraVendedor) {
    this.retiraCliente = false;
    this.statusTransport=23;
    this.TranspCode = '';
    this.Driver = '';
  }
  else{
    this.statusTransport=null;
  }
}

toggleRetiraCliente() {
  if (this.retiraCliente) {
    this.retiraVendedor = false;
    this.statusTransport=24;
    this.TranspCode = '';
    this.Driver = '';
  } 
  
  else{
    this.statusTransport=null;
  }
}

onSelectTransport() {
  this.retiraVendedor = false;
  this.retiraCliente = false;
  this.statusTransport=null;
  
}

addTransport(){
  if(this.statusTransport!==23 && this.statusTransport!==24 ){
  const modalRef = this.modalService.open(MdlAddTransportComponent, {
    size: 'sm',
    keyboard: false,
    backdrop: 'static',
    windowClass: 'modal-1080',
  });
  modalRef.result.then(() => {
    this.api.showLoading();
    this.api.getTransports().subscribe((data:any)=>{
      this.transports = data;
    })
    this.api.hideLoading();
    
  });
    }
  else{
    this.api.showToastError("Ha señalado que retira Cliente o Vendedor. Debe desmarcar para agregar conductor o transporte.");
  }

}

addDriver(){
  if(this.statusTransport!==23 && this.statusTransport!==24 ){
  const modalRef = this.modalService.open(MdladdDriverComponent, {
    size: "sm",
    keyboard: false,
    backdrop: 'static',
    windowClass: 'modal-1080'
  });   
  modalRef.result.then(() => {
    this.api.showLoading();
    this.api.getCarriers().subscribe((data:any)=>{
      console.log(data)
      this.drivers = data;
    })
    this.api.hideLoading();
    
  });
  }
  else{
    this.api.showToastError("Ha señalado que retira Cliente o Vendedor. Debe desmarcar para agregar conductor o transporte.");
  }
}


updateOrderData() {
  for (const order of this.myorders) {
      if (this.orderSelections[order.OrderCode]) {
        const orderExists = this.orderData.some(item => item.OrderCode === order.OrderCode);

        if (!orderExists) {
          this.orderData.push(order);
        }
      } else {
        const index = this.orderData.findIndex(item => item.OrderCode === order.OrderCode);
        if (index !== -1) {
          this.orderData.splice(index, 1);
        }
      }
    
  }

}

onRemoveOrder(orderCode: string) {
  const index = this.orderData.findIndex(item => item.OrderCode === orderCode);
  if (index !== -1) {
    this.orderData.splice(index, 1);
  }
  this.orderSelections[orderCode] = false;
}

  
createRoute() {
  if (this.statusTransport==24 && this.orderData.length>1){
    return this.api.showToastError("Cliente no puede retirar más de una orden");
  }

  if (((this.Driver !== '' && this.TranspCode !== '') || this.statusTransport!=null) /* && this.routeDate!=undefined */) {
  this.api.showLoading();
  this.api.checkStatus({OrdersCode: this.orderData}).subscribe((data: any) => {
    if(data.length==0){
  this.api.addPacking({
    PackingNumber: this.orderData.length
  }).pipe(
    switchMap((data: any) => {
      this.idDelivery = data.Packing_id;
      return this.api.addPackingDetail({
        PackingId: this.idDelivery,
        OrderData: this.orderData
      }).pipe(
        catchError(error => {
          this.api.hideLoading();
          this.api.showToastError(error.error.error);
          throw error;  // Rethrow the error to be caught by the outer error handler
        })
      );
    }),
    switchMap((response: any) => {
        const allSameRoute = this.orderData.every(order => order.RouteName === this.orderData[0].RouteName);
        let routeParameter: string;
        if (allSameRoute) {
          routeParameter = this.orderData[0].RouteName;
        } else {
          routeParameter = '';
        }
        return this.api.assignRoute({
          DriverCode: this.Driver,
          TranspCode: this.TranspCode,
          PackingId: this.idDelivery,
          Status: this.statusTransport,
          Created_At: this.routeDate,
          OrderData: this.orderData,
          Route: routeParameter
        }).pipe(
        catchError(error => {
          this.api.hideLoading();
          this.api.showToastError(error.error.error);
          throw error;  // Rethrow the error to be caught by the final error handler
        })
      );
    })
  ).subscribe(
    (data: any) => {
      this.api.hideLoading();
      this.api.showToastOk(data.message);
      this.router.navigate(['/adm-routes']);
    },
    (error: any) => {
      this.api.hideLoading();
      this.api.showToastError(error.error.error);
    }
  );}
    else{
      data.forEach(item => {
        const index = this.orderData.findIndex(route => route.OrderCode === item.OrderCode);
        if (index !== -1) {
          this.orderData.splice(index, 1);
        }
      });
      this.routeCode = null;
      this.cities=[]
      this.api.hideLoading();
      this.api.showToastError("Algunos pedidos cambiaron su status. Revise la asignación.");

    }
  })}
    else{
      
      this.api.showToastError("Complete todos los campos correctamente");
    }
}

setRoute() {
  if (this.statusTransport==24 && this.orderData.length>1){
    return this.api.showToastError("Cliente no puede retirar más de una orden");
  }
  if (((this.Driver !== '' && this.TranspCode !== '') || this.statusTransport!=null) /* && this.routeDate!=undefined */) {
    this.api.showLoading();
    this.api.checkStatus({OrdersCode: this.orderData}).subscribe((data: any) => {
      if(data.length==0 || (data.length > 0 && data.every(item => this.backupOrderData.some(order => order.OrderCode === item.OrderCode)))){
    this.api.settingPacking({
      PackingId: Number(this.id),
      PackingNumber: this.orderData.length}).pipe(
      switchMap(() => {
        return this.api.DeletePackingDetail({
          PackingId: Number(this.id)
        });
      }),
      switchMap((data: any) => {
        this.idDelivery = data.Packing_id;
        return this.api.addPackingDetail({
          PackingId: Number(this.id),
          OrderData: this.orderData
        }).pipe(
          catchError(error => {
            this.api.hideLoading();
            this.api.showToastError(error.error.error);
            throw error;
          })
        );
      }),
      switchMap((response: any) => {
        const allSameRoute = this.orderData.every(order => order.RouteName === this.orderData[0].RouteName);
        let routeParameter: string;
        if (allSameRoute) {
          routeParameter = this.orderData[0].RouteName;
        } else {
          routeParameter = '';
        }
        console.log(this.Driver)
        return this.api.setAssignedRoute({
          DriverCode: this.Driver,
          TranspCode: this.TranspCode,
          PackingId: Number(this.id),
          Status: this.statusTransport,
          Created_At: this.routeDate,
          OrderData: this.orderData,
          Route: routeParameter          
        }).pipe(
          catchError(error => {
            this.api.hideLoading();
            this.api.showToastError(error.error.error);
            throw error;
          })
        );
      })
    ).subscribe(
      (data: any) => {
        this.api.hideLoading();
        this.api.showToastOk(data.message);
        this.router.navigate(['/adm-routes']);
      },
      (error: any) => {
        this.api.hideLoading();
        this.api.showToastError(error.error.error);
      }
    );}
    else{
      data.forEach(item => {
        const index = this.orderData.findIndex(route => route.OrderCode === item.OrderCode);
        if (index !== -1 && !this.backupOrderData.some(route => route.OrderCode === item.OrderCode)) {
          this.orderData.splice(index, 1);
        }
      });
      
      this.routeCode = null;
      this.cities=[]
      this.api.hideLoading();
      this.api.showToastError("Algunos pedidos cambiaron su status. Revise la asignación.");
    }
  })
  } else {
    this.api.showToastError("Complete todos los campos correctamente");
  }
}

  
async searchBilling(){
  try{
    this.api.showLoading();
    await this.api.getInvoice({
      FolioNum:this.filterText
    }).subscribe((data:any)=>{
      if(data.length==0){
        this.api.showToastError("No se encontró información de la factura ingresada o esta ya se encuentra cerrada.");
        this.api.hideLoading();
        return
      }
      const orderExists = this.orderData.some(item => item.OrderCode === data[0].OrderCode);
      if (orderExists) {
        this.api.showToastError("Ya has ingresado esta factura.");
        this.api.hideLoading();
        return
      }
      this.orderData.push(data[0]);
      this.api.hideLoading();
      this.filterText='';
    })
  }
  catch(error){
    this.api.hideLoading();
    this.api.showToastError("Error al consultar la factura ingresada");
  } 
  
}



    onSelectAll(items: any) {
    console.log(items);
  }
  onItemDeSelect(item: any){
    console.log(this.theitems)
  }

  sortRoutesByName(groutes) {
    let groutesSorted: any = groutes;
    groutesSorted.sort((a, b) => {
      if (a.RouteName < b.RouteName) {
        return -1;
      }
      if (a.RouteName > b.RouteName) {
        return 1;
      }
      return 0;
    });
    return groutesSorted
  }

  sortSellersByName(sellers) {
    let sellersSorted: any = sellers;
    sellersSorted.sort((a, b) => {
      if (a.SlpName < b.SlpName) {
        return -1;
      }
      if (a.SlpName > b.SlpName) {
        return 1;
      }
      return 0;
    });
    return sellersSorted
  }

  
  selectRoute(): void {
    const sellers = this.selectedSellers.join(",");
    const routes = this.selectedRoutes.map(value => `'${value}'`).join(', ');

    this.loader = true;
    this.api.getOrdersByRoute({
      RouteCode: routes,
      SlpCode: sellers
    }).subscribe((data: any) => {
      data = data.filter(item => item.DocEntry !== 0);
      const dataStatus = data.filter(item => item.Status >= 0 && item.Status <= 10);
      const status10Pieces = data.filter(item => item.Status === 12 && this.backupOrderData.some(order => order.OrderCode === item.OrderCode));
      data = status10Pieces.concat(dataStatus);
      data.forEach(item => {
        const route = this.groutes.find(route => route.RouteID === item.RouteID);
        if (route) {
          item.RouteName = route.RouteName;
        }
      });

      this.cities = this.groupOrdersByCity(data);

      if (this.selectedRoutes.length === 0 && this.selectedSellers.length === 0) {
        this.myorders = [];
        this.cities = [];
      } else {
        this.myorders = data;
      }

      this.backupOrders = this.myorders;
      this.loader = false;
    });
  }

  groupOrdersByCity(orders: any[]): any[] {
    const groupedOrders = [];
    for (const order of orders) {
      const existingGroup = groupedOrders.find((group) => group.city === order.City);
      if (existingGroup) {
        existingGroup.count++;
      } else {
        groupedOrders.push({ city: order.City, count: 1 });
      }
    }
    return groupedOrders;
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  toggleSelection(routeCode: string): void {
    const index = this.selectedRoutes.indexOf(routeCode);
    if (index > -1) {
      this.selectedRoutes.splice(index, 1);
    } else {
      this.selectedRoutes.push(routeCode);
    }
    this.updateRouteCode();
  }

  updateRouteCode(): void {
    this.routeCode = this.selectedRoutes.join(',');
    this.selectRoute(); // Implementa tu lógica de selección de ruta aquí
  }

  toggleSellerDropdown(): void {
    this.dropdownSellerOpen = !this.dropdownSellerOpen;
  }

  toggleSellerSelection(sellerCode: string): void {
    const index = this.selectedSellers.indexOf(sellerCode);
    if (index > -1) {
      this.selectedSellers.splice(index, 1);
    } else {
      this.selectedSellers.push(sellerCode);
    }
    this.updateSellerCode();
  }

  updateSellerCode(): void {
    this.seller = this.selectedSellers.join(',');
    this.selectRoute();
  }


}
