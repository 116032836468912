import { Component, ElementRef, ViewChild } from '@angular/core';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'html2pdf',
})
export class Html2PdfComponent  {
  @ViewChild('content') content: ElementRef;

  constructor(private api: ApiService) {}
  async generatePdf(orderData) {

    const contadoOrders = orderData.filter(order => ![1, 2, 3, 4, 5, 12, 13, 14, 15].includes(order["OrderInfo"][0].GroupNum));
    const creditoOrders = orderData.filter(order => [1, 2, 3, 4, 5, 12, 13, 14, 15].includes(order["OrderInfo"][0].GroupNum));

    let encabezado = `<div style="text-align: center; font-size: 28px; margin-bottom: 20px">Informe N° ${orderData[0].Packing_id}</div>`; 
    encabezado += `<div style="text-align: center; font-size: 28px; margin-bottom: 20px;"> ${this.obtenerFechaFormateada()} </div>`;
    encabezado += `<div style="text-align: center; font-size: 28px; margin-bottom: 20px;">${orderData[0].NameDriver} </div>`;
    let contadoHtml = '';
    if (contadoOrders.length > 0) {
        contadoHtml += `<div style="text-align: center; font-size: 28px; margin-bottom: 20px">Forma de Pago: Contado</div>`;
        contadoHtml += '<table style="border-collapse: collapse; margin-left: 20px">';
        contadoHtml += '<thead style="border-bottom: 1px solid #000;"><tr><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Folio</th><th style="border: 1px solid #000; width: auto;padding-top:20px; font-size: 20px">Rut</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Razón Social</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Dirección</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Bultos</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Total</th></th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Vendedor</th><th style="border: 1px solid #000; width: 300px; padding-top:20px; font-size: 20px">Obs.</th></tr></thead>';
        contadoHtml += '<tbody>';
        contadoOrders.forEach(order => {
            const totalInvoice = Math.round(order["InvoiceDetails"][0].TotalInvoice);
            const bultos =order["InvoiceDetails"][0].U_Bultos;
            const addThousandSeparators = (num: number): string => {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            };
            contadoHtml += `<tr>
                  <td style="border: 1px solid #000; font-size: 20px;width: auto; padding-top:20px">${order.FolioNum}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.LicTradNum}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.CardName}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.Street} ${order.RouteName} </td>
                  <td style="border: 1px solid #000; font-size: 20px; text-align:center; width: auto; padding-top:20px">${bultos}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">$${addThousandSeparators(totalInvoice)}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.Vendedor}</td>
                  <td style="border: 1px solid #000; width: 300px;"></td>
              </tr>`;
        });
        contadoHtml += '</tbody></table>';
    }

    const totalContado = contadoOrders.reduce((total, order) => {
        return total + Math.round(order["InvoiceDetails"][0].TotalInvoice);
    }, 0);

    let totalContadoHtml = '';
    if (contadoOrders.length > 0) {
        totalContadoHtml += `<div style="text-align: center; font-size: 28px; margin-bottom: 20px">Total Contado</div>`;
        totalContadoHtml += '<table style="border-collapse: collapse; margin-left: 20px">';
        totalContadoHtml += '<tbody>';
        totalContadoHtml += `<tr><td style="border: 1px solid #000; width: 200px; font-size: 20px; padding-top:20px">Total Neto</td><td style="border: 1px solid #000; width: 200px; font-size: 20px; padding-top:20px">$${Math.round(totalContado*100/119).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td><td style="border: 1px solid #000; font-size: 20px; width: 200px; padding-top:20px">Total Bruto</td><td style="border: 1px solid #000; width: 200px; font-size: 20px;  padding-top:20px">$${totalContado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td></tr>`;
        totalContadoHtml += '</tbody></table>';
    }

    // Generar el HTML para las ordenes de crédito
    let creditoHtml = '';
    if (creditoOrders.length > 0) {
        creditoHtml += `<div style="text-align: center; font-size: 28px; margin-bottom: 20px">Forma de Pago: Crédito</div>`;
        creditoHtml += '<table style="border-collapse: collapse; margin-left: 20px">';
        creditoHtml += '<thead style="border-bottom: 1px solid #000;"><tr><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Folio</th><th style="border: 1px solid #000; width: auto;padding-top:20px; font-size: 20px">Rut</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Razón Social</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Dirección</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Bultos</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Total</th><th style="border: 1px solid #000; width: auto; padding-top:20px; font-size: 20px">Vendedor</th><th style="border: 1px solid #000; width: 300px; padding-top:20px; font-size: 20px">Obs.</th></tr></thead>';
        creditoHtml += '<tbody>';
        creditoOrders.forEach(order => {
            const totalInvoice = Math.round(order["InvoiceDetails"][0].TotalInvoice);
            const bultos =order["InvoiceDetails"][0].U_Bultos;
            const addThousandSeparators = (num: number): string => {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            };
            creditoHtml += `<tr>
                  <td style="border: 1px solid #000; font-size: 20px;width: auto; padding-top:20px">${order.FolioNum}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.LicTradNum}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.CardName}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.Street} ${order.RouteName} </td>
                  <td style="border: 1px solid #000; font-size: 20px; text-align:center; width: auto; padding-top:20px">${bultos}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">$${addThousandSeparators(totalInvoice)}</td>
                  <td style="border: 1px solid #000; font-size: 20px; width: auto; padding-top:20px">${order.Vendedor}</td>
                  <td style="border: 1px solid #000; width: 300px;"></td>
              </tr>`;
        });
        creditoHtml += '</tbody></table>';
    }

    const totalCredito = creditoOrders.reduce((total, order) => {
        return total + Math.round(order["InvoiceDetails"][0].TotalInvoice);
    }, 0);


    let totalCreditoHtml = '';

    if (creditoOrders.length > 0) {
        totalCreditoHtml += `<div style="text-align: center; font-size: 28px; margin-bottom: 20px">Total Crédito</div>`;
        totalCreditoHtml += '<table style="border-collapse: collapse; margin-left: 20px">';
        totalCreditoHtml += '<tbody>';
        totalCreditoHtml += `<tr><td style="border: 1px solid #000; width: 200px; font-size: 20px;width: auto; padding-top:20px">Total Neto</td><td style="border: 1px solid #000; width: 200px; font-size: 20px; width: auto; padding-top:20px">$${Math.round(totalContado*100/119).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td><td style="border: 1px solid #000; width: 200px; font-size: 20px; width: auto; padding-top:20px">Total Bruto</td><td style="border: 1px solid #000; width: 200px; font-size: 20px; width: auto; padding-top:20px">$${totalCredito.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td></tr>`;
        totalCreditoHtml += '</tbody></table>';
    }


    const finalHtml = contadoOrders.length > 0 && creditoOrders.length > 0 ? contadoHtml + '<br>' + totalContadoHtml + '<br>' + creditoHtml + '<br>' + totalCreditoHtml : contadoOrders.length > 0 ? contadoHtml + '<br>' + totalContadoHtml : creditoOrders.length > 0 ? creditoHtml + '<br>' + totalCreditoHtml : '';

    const finalContadoHtml = encabezado+ '<br>' +contadoHtml + '<br>' + totalContadoHtml;
     
    const finalCreditoHtml = encabezado+ '<br>' + creditoHtml + '<br>' + totalCreditoHtml;


    // Verificar si hay contenido para generar el PDF
    if (finalContadoHtml !== '' || finalCreditoHtml !=='') {
       
        const pdf = new jspdf('p', 'mm', 'letter');

        if (contadoOrders.length > 0) {
            const divElementContado = document.createElement('div');
            divElementContado.style.position = 'absolute';
            divElementContado.style.top = '50px';
            divElementContado.style.width = '100%';
            divElementContado.innerHTML = finalContadoHtml;
            document.body.appendChild(divElementContado);
            const canvas1 = await html2canvas(divElementContado);
            const imgWidth = 250;
            const imgHeight = canvas1.height * imgWidth / canvas1.width;
            const contentDataURL = canvas1.toDataURL('image/png');
            const position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            document.body.removeChild(divElementContado);
        }

        if (creditoOrders.length > 0) {
            const divElementCredito = document.createElement('div');
            divElementCredito.style.position = 'absolute';
            divElementCredito.style.top = '50px';
            divElementCredito.style.width = '100%';
            divElementCredito.innerHTML = finalCreditoHtml;
            document.body.appendChild(divElementCredito);
            const canvas2 = await html2canvas(divElementCredito);
            const imgWidth = 250;
            const imgHeight = canvas2.height * imgWidth / canvas2.width;
            const contentDataURL = canvas2.toDataURL('image/png');
            const position = 0;
            if(contadoOrders.length > 0){
                pdf.addPage();
            }
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            document.body.removeChild(divElementCredito);
        }
 
        pdf.save(`${this.obtenerFechaFormateada()}-${orderData[0].Packing_id}`);
        this.api.hideLoading();
         
    } else {
        // No hay contenido para generar el PDF
        console.log('No hay contenido para generar el PDF');
    }
}



obtenerFechaFormateada() {
    const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
  
    const fechaActual = new Date();
    //fechaActual.setDate(fechaActual.getDate() + 1);

    const diaSemana = diasSemana[fechaActual.getDay()];
    const dia = fechaActual.getDate();
    const mes = meses[fechaActual.getMonth()];
    const año = fechaActual.getFullYear();
  
    return `${diaSemana}, ${dia} de ${mes} de ${año}`;
  }
  

}