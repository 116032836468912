import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';



@Component({
  selector: 'app-mdl-add-driver',
  templateUrl: './mdl-add-driver.component.html',
  styleUrls: ['./mdl-add-driver.component.scss']
})
export class MdladdDriverComponent implements OnInit {

  public driver: any = {
    Name:'',
    Surname:'',
    CardCode: ''
  };

 


  constructor(private api: ApiService, public activeModal: NgbActiveModal) {
    
   }

  ngOnInit(): void {
  }

/*   validateRut(rut:string) {
    this.api.showLoading();
    this.api.checkNotExistsRut(rut).subscribe((data:any) => {
      this.api.hideLoading();
      this.customer.CardCode = data.CardCode;
      this.customer.LicTradNum = data.LicTradNum;
      this.address.CardCode = this.customer.CardCode;
      this.contact.CardCode = this.customer.CardCode;
    }, (err:any) => {
      this.api.hideLoading();
      this.api.showToastError(err.error.error);
      this.customer.LicTradNum = '';
      this.customer.CardCode = '';
    });
  } */

    async save() {
      this.api.showLoading();
      console.log(this.driver);

      if (!this.driver.Surname || !this.driver.Name || !this.driver.CardCode) {
        this.api.hideLoading();
        return this.api.showError("Complete todos los campos");
      }
 
      let backupDriver = JSON.parse(JSON.stringify(this.driver));

      backupDriver.Name = backupDriver.Name + ' ' + backupDriver.Surname;

      this.api.setCarrier(backupDriver).subscribe((data: any) => {
        this.saveUser(data.id[0].DriverCode);
      }, (err: any) => {
        this.api.hideLoading();
        this.api.showError(err.error.error);
      });
    }
    

  closeModal(){
    this.activeModal.close();
  }

  async saveUser(transpCode:number){
    this.api.showLoading();
    let usuario:any = {
      Active: true,
      Valdivia: true,
      Name: this.driver.Name , 
      Surname:this.driver.Surname, 
      Username: this.transformName(), 
      Password: 'asdf1234', 
      Email: null, 
      Phone:null, 
      Department: '',  
      TranspCode: transpCode,
      UserType:'T',
      SlpCode: -1,
      Mode: '',
      DirectOrders: false,
      dropTokens: false,
      AuthOrders: false,
      AppAccess: true,
      MapViewer: false,
      isCheckAccounting: false,
      PermList: [{ModuleCode: 6, ModuleName: "Despacho"}]
    }
    this.api.saveUser(usuario).subscribe((data:any)=>{
      this.api.hideLoading(); 
      this.api.showOk("Cambios guardados correctamente").then(() => { this.activeModal.close();});
    }, (err:any) => {
      this.api.hideLoading(); 
      this.api.showToastError(err.error.error);
    }); 
  }

   transformName(): string {
    const lowerCaseName = this.driver.Name.toLowerCase().trim();
    const lowerCaseSurname = this.driver.Surname.toLowerCase().trim();
    const nameParts = lowerCaseName.split(' ');
    const surnameParts = lowerCaseSurname.split(' ');
    return `${nameParts[0]}.${surnameParts[0]}`;
  }
  

}
