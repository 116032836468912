import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mdl-search-customer',
  templateUrl: './mdl-search-customer.component.html',
  styleUrls: ['./mdl-search-customer.component.scss']
})
export class MdlSearchCustomerComponent implements OnInit {

  public search: string = '';
  public resultados:any=[];
  public searchPressed: boolean = false;

  constructor(private api: ApiService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  searchCustomer(){
    if (this.search.length > 2) {
      this.searchPressed = true;
      this.api.showLoading();
      this.api.getCustomers({
        search:this.search
      }).subscribe((data:any)=>{
        this.resultados = data;
        this.api.hideLoading();
      });
    }
    else {
      this.api.showToastError("Ingrese texto más largo");
    }
  }

  selectUser(user:any){
    this.activeModal.close(user);
  }

  closeModal(){
    this.activeModal.close();
  }
}
