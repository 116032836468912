import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';




@Component({
  selector: 'app-mdl-image',
  templateUrl: './mdl-image.component.html',
  styleUrls: ['./mdl-image.component.scss']
})
export class MdlimageComponent implements OnInit {
  @Input() imageUrl: string; 
  @Input() Packing_id: string;
@Input() imagesUrl: string[];
@Input() condition: string; 
  base64Image: string;

  currentImageIndex = 0;

  constructor(private api: ApiService, public activeModal: NgbActiveModal) {
   }

  ngOnInit(): void {
  }

  nextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.imagesUrl.length;
  }

  prevImage() {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.imagesUrl.length) % this.imagesUrl.length;
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.api.showLoading();
      this.base64Image = reader.result as string;
      this.onUpload(this.Packing_id); 
      this.activeModal.close();
    };
    reader.readAsDataURL(file);
  }
  
  onUpload(id) {
    if (this.base64Image) {
      const blobImage = this.dataURItoBlob(this.base64Image);      
      this.api.upload("/data-upload", blobImage, {},id).subscribe(
        (data: any) => {
          this.api.hideLoading();
          this.api.showOk("Imagen ha sido cargada");
        },
        (err: any) => {
          this.api.hideLoading();
          this.api.showError('Error al cargar la imagen');
        }
      );
    }
  }
  

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }


  closeModal(){
    this.activeModal.close();
  }

}
