import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  public login: any = {
    username: '',
    password: ''
  }
  public loading: boolean = false;

  constructor(public api: ApiService, public router: Router, public ar: ActivatedRoute) {}

  ngOnInit() {}

  conectar() {
    this.loading = true;
    this.api.login(this.login).subscribe((data:any) => {
      localStorage.setItem('PacelToken', data.token);
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      },500);
    }, (err:any) => {
      this.loading = false;
      this.api.showToastError(err.error.error);
    });
  }
}
