import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import Swal from 'sweetalert2' 
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
 
  public fireLoading: EventEmitter<boolean> = new EventEmitter();
  public updatePermissions: EventEmitter<boolean> = new EventEmitter();
  public notificationInbox: EventEmitter<any> = new EventEmitter();
  public notificationUpdate: EventEmitter<any> = new EventEmitter();
  public modules = [];

  constructor(private http: HttpClient) { }
  login(t)
  {
      return this.http.post(environment.api + "/login?mode=WEB", t)
  }
  getToken()
  {
      return localStorage.getItem("PacelToken")
  }
  setToken(t)
  {
      localStorage.setItem("PacelToken", t)
  }
  get(t, e?:any)
  {
      return this.http.get(environment.api + t,
      {
          params: e,
          headers: new HttpHeaders().append("Authorization", this.getToken())
      })
  }
  post(t, e)
  {
      return this.http.post(environment.api + t, e,
      {
          headers: new HttpHeaders().append("Authorization", this.getToken())
      })
  }
  upload(t, e, n, id)
  {
      let r = new FormData;
      if (r.append("upload", e, "avatar.jpg"), n) { for (var i in n) r.append(i, n[i]); }
      r.append("Packing_id", id.toString());
      const o = { headers: new HttpHeaders().append("Authorization", this.getToken())};
      return this.http.post(environment.api + t, r, o)
  }
  deleteFile(urlToDelete: string) {
    const headers = new HttpHeaders().append("Authorization", this.getToken());
      const body = { deleteUrl: urlToDelete };
      console.log("entro")
    return this.http.post(environment.api + '/delete-file', body);
  }
  
  showLoading()
  {
      this.fireLoading.emit(true)
  }
  hideLoading()
  {
      this.fireLoading.emit(!1)
  }
  getPeriods()
  {
      return this.get("/periods")
  }
  getMe() {
    return this.get("/me");
  }
  getDashboard(t, e)
  {
      return this.get("/dashboard?from=" + t + "&to=" + e)
  }
  getUserAccess()
  {
      return this.get("/permissions")
  }
  getUsers(t?:any)
  {
      return this.post("/users", t)
  }
  getUserSellers()
  {
      return this.get("/userSellers/getSellers")
  }
  getModules()
  {
      return this.get("/modules")
  }
  saveUser(t)
  {
      return this.post("/users/save", t)
  }
  getUser(t)
  {
      return this.get("/users/" + t)
  }
  removeUser(t)
  {
      return this.post("/users/remove",
      {
          user_id: t
      })
  }
  removePayments(t)
  {
      return this.post("/payments/drop",
      {
          PreCode: t
      })
  }
  getCustomers(t)
  {
      return this.post("/customers", t)
  }
  getCustomer(t)
  {
      return this.get("/customer/" + t)
  }
  getCustomerStatus(t) {
    return this.post("/customer/status", t)
  }
  checkVaucher(t) {
    return this.post("/payments/checkvoucher", t)
  }
  getCustomerLogs(t) {
    return this.get("/customer-log/" + t)
  }
  getValidVoucher(t) {
    return this.get("/payments/CHeckExistingVoucher/" + t)
  }
  addCustomer(t)
  {
      return this.post("/customers/add", t)
  }
  getCustomersAddr(t)
  {
      return this.post("/customers/address", t)
  }
  setCustomerAddress(t)
  {
      return this.post("/customers/address/add", t)
  }
  getCustomerContact(t)
  {
      return this.post("/customers/contacts", t)
  }
  setCustomerContact(t)
  {
      return this.post("/customers/contacts/add", t)
  }
  saveCustomer(t)
  {
      return this.post("/customers/save", t)
  }
  getPriceList()
  {
      return this.get("/pricelist")
  }
  checkNotExistsRut(t)
  {
      return this.post("/not-exists-rut",
      {
          rut: t
      })
  }
  getSellers(t?:any)
  {
      return this.post("/sellers",
      {
          search: t || ""
      })
  }
  getStatuses(t?:any) {    
    return this.post("/statuses",
    {
        search: t || ""
    })
  }
  getItem(t)
  {
      return this.get("/items/" + t)
  }
  getItemsGroup()
  {
      return this.get("/itemsgroup")
  }
  getItems(t)
  {
      return this.post("/items", t)
  }
  getItemStock(t)
  {
      return this.post("/itemstock", t)
  }
  getItemPrice(t)
  {
      return this.post("/itemprice", t)
  }
  getWarehouse()
  {
      return this.get("/warehouse")
  }
  getProviders()
  {
      return this.get("/providers")
  }
  saveItem(t)
  {
      return this.post("/items/save", t)
  }
  getTransports(t?:any)
  {
      return this.post("/transport", t)
  }
  manageTransport(t)
  {
      return this.post("/man-transp", t)
  }
  saveOrder(t)
  {
      return this.post("/orders/save", t)
  }
  authOrder(t)
  {
      return this.post("/orders/auth", t)
  }
  dropOrder(t)
  {
      return this.post("/orders/drop", t)
  }
  getOrdersByRoute(t)
  {
      return this.post("/ord-rout", t)
  }
  getCarriers(t?: any)
  {
      return this.post("/gt-carriers", t)
  }
  setCarrier(t)
  {
      return this.post("/st-carrier", t)
  }
  assignRoute(t)
  {
      return this.post("/as-route", t)
  }
  assignOrderRoute(t)
  {
      return this.post("/st-ascode", t)
  }
  getAssignedList(t: any = null) {
    const route = t ? `/gt-asroute/${t}` : '/gt-asroute';
    return this.get(route);
  }
  getOrdersByAssignedRoute(t)
  {
      return this.post("/gtas-rout", t)
  }
  getOrdersByPackingId(t)
  {
      return this.post("/orders-id", t)
  }
  getDirections(t)
  {
      return this.post("/address-map", t)
  }
  deleteByPackingId(t)
  {
      return this.post("/delete-packing",t)
  }
  setPacking(t)
  {
      return this.post("/delete-packing", t)
  }
  setStatusOrder(t)
  {
      return this.post("/routing/set-status", t)
  }
  getRoute(t?:any)
  {
      return this.get("/routes/" + t)
  }
  saveRoute(t)
  {
      return this.post("/routes/save", t)
  }
  getOrder(t)
  {
      return this.post("/orders/get", t)
  }
  getOrderDetails(t)
  {
      return this.post("/orders/details", t)
  }
  settingPacking(t)
  {
      return this.post("/packing/set", t)
  }
  setAssignedRoute(t)
  {
    return this.post("/as-route/set", t)
  }
  addPacking(t)
  {
      return this.post("/packing/save", t)
  }
  DeletePackingDetail(t)
  {
      return this.post("/packing-detail/del", t)
  }
  setPackingDetail(t)
  {
      return this.post("/st-packdet", t)
  }
  addPackingDetail(t)
  {
      return this.post("/packing-detail/save", t)
  }
  setOrderDetail(t)
  {
      return this.post("/st-orddet", t)
  }
  getAllOrders(t)
  {
      return this.post("/orders", t)
  }
  getPayments(t)
  {
      return this.post("/payments", t)
  }
  getPaymentDriver(t)
  {
      return this.post("/payment-driver", t)
  }
  getAllRoutes(t?:any)
  {
      return this.get("/routes?" + (t ? "search=" + t : ""))
  }
  authPayment(t)
  {
      return this.post("/payments/auth", t)
  }
  authMultiPayment(t)
  {
      return this.post("/payments/multiauth", t)
  }
  saveAccounts(data:any) {
    return this.post('/config/accounts', data);
  }
  getAccounts() {
    return this.get('/config/accounts');
  }
  checkStatus(t){
    return this.post("/check-status", t)
  }
  checkOrders(){
    return this.get('/orders/check');
  }
  checkOrder(data:any){
    return this.post('/orders/checkorder', data);
  }
  approveCredit(t){
    return this.post('/routing/approveCredit', t);
  }
  getInvoiceDetail(userData:any){
    return this.post('/invoice/details', userData);
  }
  getInvoice(userData:any){
    return this.post('/invoice', userData);
  }
  getInfoReports(t){
    return this.post('/orders/reports', t);
  }
  getInfoReportsReturns(t){
    return this.post('/orders/reports-returns', t);
  }
  getDevolutionReason(){
    return this.get('/devolution-reason');
  }
   getOrderInfo(t)
  {
      return this.post("/orders/get-info",t)
  } 
  showToastError(t)
  {
      return Swal.fire(
      {
          toast: true,
          icon: "error",
          title: "Error",
          text: t,
          timerProgressBar: true,
          timer: 3000,
          showConfirmButton: !1,
          showCancelButton: !1,
          position: "top-right"
      })
  }
  showToastOk(t)
  {
      return Swal.fire(
      {
          toast: true,
          icon: "success",
          title: "Listo",
          text: t,
          timerProgressBar: true,
          timer: 3000,
          showConfirmButton: !1,
          showCancelButton: !1,
          position: "top-right"
      })
  }
  showError(t)
  {
      return Swal.fire(
      {
          icon: "error",
          title: "Error",
          text: t,
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
          showCancelButton: !1
      })
  }
  showOk(t)
  {
      return Swal.fire(
      {
          icon: "success",
          title: "Listo",
          text: t,
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
          showCancelButton: !1
      })
  }
  showConfirm(t)
  {
      return Swal.fire(
      {
          title: "\xbfEst\xe1 seguro?",
          text: t,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar"
      })
  }
  getKey()
  {
      return "_" + Math.random().toString(36).substr(2, 9)
  }
}
