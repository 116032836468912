import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mdl-search-item',
  templateUrl: './mdl-search-item.component.html',
  styleUrls: ['./mdl-search-item.component.scss']
})
export class MdlSearchItemComponent implements OnInit {

  public search: string = '';
  public resultados:any=[];

  public p = 1;

  constructor(private api: ApiService, public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
  }


  searchItem(){
    if (this.search.length > 2) {
      this.api.showLoading();
      this.api.getItems({
        search:this.search
      }).subscribe((data:any)=>{
        this.api.hideLoading();
        this.resultados = data;
      });
    }
  }

  selectItem(item:any){
    this.activeModal.close(item);
  }

  closeModal(){
    this.activeModal.close();
  }
}
