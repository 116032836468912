import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";

@Component({
  selector: 'app-packing',
  templateUrl: './packing.component.html',
  styleUrls: ['./packing.component.scss']
})
export class PackingComponent implements OnInit {

  public orderDet: any = [];
  public date: Date = new Date();
  public count: number = 1;
  public packages:any = [];
  public packdet:any = [];
  public packingCode:number = 0;
  public detail:any = {
    PackingCode:'',
    ItemCode:'',
    Quantity:'',
    Date:'',
    PackingNumber:''
  };

  public search: string;

  public isHidden: boolean= true;


  constructor(private api: ApiService) { }

  ngOnInit(): void {
    console.log('this.isHidden 1: ', this.isHidden);
  }

  onChange(option, event){
    console.log(option.ItemCode)
    var packDate = this.date.getDate() +'/'+ String(this.date.getMonth() + 1).padStart(2, '0') +'/'+ this.date.getFullYear();
    var valor = 0;

    if (option.LeftQuantity < event.target.value) {
      event.target.value = ''
      alert('Cantidad supera a lo solicitado');
    }else{
      this.detail.PackingCode = '';
      this.detail.ItemCode = option.ItemCode;
      this.detail.Quantity = event.target.value;
      this.detail.Date     = packDate;
      this.detail.PackingNumber = '';
      if (this.packdet.length === 0) {
        this.packdet.push(this.detail);
      }else{
        for (let i = 0; i < this.packdet.length; i++) {
          if (option.ItemCode === this.packdet[i].ItemCode) {
            this.packdet.splice(i,1);
          }
        }
        this.packdet.push(this.detail);
      }
      this.detail = {
        PackingCode:'',
        ItemCode:'',
        Quantity:'',
        Date:'',
        PackingNumber:''
      }
      console.log(this.packdet);
    }
    
    
    
  }

  addPackage(){
    console.log('this.isHidden 2: ', this.isHidden);
    var packCode = 'Bulto 1411 ' + this.count;
    var packDate = this.date.getDate() +'/'+ String(this.date.getMonth() + 1).padStart(2, '0') +'/'+ this.date.getFullYear();
    let completado = 0;

    this.isHidden = false;
    this.api.setPacking({
      PackingCode:this.packingCode,
      PackingNumber:this.count,
      OrderCode:1411,
      UserCode:1,
      Created_At:packDate
    }).subscribe((data:any)=>{
      this.packingCode = data[0].PackingCode;
      for (let i = 0; i < this.packdet.length; i++) {
        this.api.setPackingDetail({
          PackingCode:this.packingCode,
          ItemCode:this.packdet[i].ItemCode,
          Quantity:this.packdet[i].Quantity,
          Date:packDate,
          PackingNumber:this.count
        }).subscribe((data:any)=>{
          completado++
          console.log(completado);
          console.log(this.packdet.length);
          if (completado == this.packdet.length) {
          this.isHidden = true;
          console.log('this.isHidden 3: ', this.isHidden);
          console.log("Listo");
          this.loadData();

          this.packdet = [];
          this.count++;
        }
        })
        
      }
      
      
    })
    this.packages.push({
      packageCode:packCode,
      packageDate:packDate
    })
    
     
  }

  loadData(){
    console.log('this.isHidden A: ', this.isHidden);
    this.api.getOrderDetails({
      OrderCode:this.search
    }).subscribe((data:any)=>{
      this.orderDet = data;
      console.log('this.isHidden B: ', this.isHidden);
      // console.log(this.orderDet)
    })
  }

}
