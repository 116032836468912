import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ApiService } from "../../services/api.service";

declare interface RouteInfo {
  path: string;
  title: string;
  rtlTitle: string;
  icon: string;
  class: string;
  code: Number;
}


export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Escritorio",
    rtlTitle: "",
    icon: "icon-bank",
    class: "",
    code:0
  },
  {
    path: "/users",
    title: "Usuarios",
    rtlTitle: "",
    icon: "icon-badge",
    class: "",
    code:1
  }, 
  {
    path: "/customers",
    title: "Clientes",
    rtlTitle: "",
    icon: "icon-single-02",
    class: "",
    code:2
  },
  {
    path: "/items",
    title: "Articulos",
    rtlTitle: "",
    icon: "icon-notes",
    class: "",
    code:3
  },
  {
    path: "/orders",
    title: "Pedidos",
    rtlTitle: "",
    icon: "icon-cart",
    class: "",
    code:4
  }, 
  {
    path: "/routing",
    title: "Despacho",
    rtlTitle: "",
    icon: "icon-map-big",
    class: "",
    code:6
  },
  {
    path: "/payments",
    title: "Pagos",
    rtlTitle: "",
    icon: "icon-coins",
    class: "",
    code:8
  }  ,
  {
    path: "/payments",
    title: "Configuración",
    rtlTitle: "",
    icon: "icon-controller",
    class: "",
    code:7
  },
  {
    path: "/payments",
    title: "Cerrar Sesión",
    rtlTitle: "",
    icon: "icon-simple-remove",
    class: "",
    code:7
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})


export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public permissions: any = [];
  public allItems: any = [];
  public activado: any=[]
  private listTitles: any[]; 
  public loadingSide = false;
  constructor(private location: Location,
              private router: Router,
              private api: ApiService) {
  
  }

 
  ngOnInit()
  {
      this.updateMenu(), this.api.updatePermissions.subscribe(t =>
      {
          this.updateMenu()
      })
  }
  updateMenu()
  {
      this.loadingSide = true;
      this.api.getModules().subscribe((t:any) =>{
          this.api.modules = t;
          this.listTitles = this.api.modules.filter(t => t), 
          this.allItems = this.api.modules.filter(t => t), 
          this.menuItems = [], 
          
          this.api.getUserAccess().subscribe(t => {
            this.permissions = t;
            for (let e = 0; e < this.allItems.length; e++) {
              for (let t = 0; t < this.permissions.length; t++) {
                if (this.allItems[e].ModuleCode == this.permissions[t].ModuleCode || this.allItems[e].ModuleCode == "0") {
                    this.menuItems.push(this.allItems[e]);
                    break;
                } 
              }
            }
            this.loadingSide = false;
            console.log(this.menuItems);
          });
          
      })
  }
  disconnect() {
    this.api.showConfirm("¿Desea salir?").then(t => {
      if (t.isConfirmed) {
        localStorage.removeItem("PacelToken");
        this.router.navigate(["/login"]);
      }
    })
  }
  isMobileMenu() {
      return !(window.innerWidth > 991)
  }
  getTitle() 
  {
      var t = this.location.prepareExternalUrl(this.location.path());
      "#" === t.charAt(0) && (t = t.slice(1));
      for (var e = 0; e < this.listTitles.length; e++)
          if (this.listTitles[e].path === t) return this.listTitles[e].title;
      return "Dashboard"
  }
}
