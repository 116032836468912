import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from 'agm-direction';
import localeCl from "@angular/common/locales/es-CL";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module"; 
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'; 
import { LoginComponent } from "./pages/login/login.component";
import { registerLocaleData } from '@angular/common';
import { PackingComponent } from './pages/packing/packing.component';
import { RouteAssignComponent } from "./pages/route-assign/route-assign.component";
import { MdlSearchCustomerComponent } from './pages/mdl-search-customer/mdl-search-customer.component';
import { MdlSearchItemComponent } from './pages/mdl-search-item/mdl-search-item.component';
import { MdladdDriverComponent } from './pages/mdl-add-driver/mdl-add-driver.component';
import { MdlAddTransportComponent } from './pages/mdl-add-transport/mdl-add-transport.component';
import { MdlimageComponent } from './pages/mdl-image/mdl-image.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { OnlynumberDirective } from "./onlynumbers.directive";
import { OnlydecimalDirective } from "./onlydecimal.directive";
import { Html2PdfComponent } from "./shared/html2pdf/html2pdf.component";

registerLocaleData(localeCl, 'es-CL')

@NgModule({
  imports: [
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule, 
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCyE_qKpyH15ZBHquvDZyH4zK8OkA4Ba1s', // SF-DUO
      libraries: ['places']
    }),
    AgmDirectionModule
  ],
  declarations: [
    AppComponent, 
    AdminLayoutComponent, 
    AuthLayoutComponent, 
    PackingComponent,
    RouteAssignComponent,
    LoginComponent,
    MdlSearchCustomerComponent,
    MdlSearchItemComponent,
    OnlynumberDirective,
    OnlydecimalDirective,
    MdladdDriverComponent,
    MdlAddTransportComponent,
    MdlimageComponent,
  ],
  providers: [{provide: LOCALE_ID, useValue: 'es-CL' }, Html2PdfComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
