import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from "src/app/services/api.service";
//import { WebSocketService } from './websocket.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  public listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  public toggleButton: any;
  public sidebarVisible: boolean;
  public notifications: any = [];
  public notificationPanelOpen: boolean = false;
  updatingNotifications: boolean = false;

  public isCollapsed = true;

  closeResult: string;

  constructor(
    location: Location,
    public element: ElementRef,
    public router: Router,
    public api: ApiService,
    public modalService: NgbModal,
    //private webSocketService: WebSocketService
  ) {
    this.location = location;
    this.sidebarVisible = false;

    this.api.notificationInbox.subscribe((data:any) => {
      this.notifications.push(data);
    });
    this.api.notificationUpdate.subscribe((data:any) => {
      let current_i = -1;
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].id == data.id) {
          this.notifications.splice(i,1);
          break;
        }
      }
      this.notifications.push(data);

      /*
      if (current_i > -1) {
        setTimeout(function() {
          this.notifications.splice(current_i,1);
        },2000);
      }
      */
    });

    this.api.checkOrders().subscribe((data: any[]) => { 
      console.log(data);
      data.forEach(order => {
        const orderCode = order.OrderCode;
        const notificationContent = `<a href="/#/orders-edit/${orderCode}">La orden ${orderCode} está en espera de aprobación de crédito </a>`;

        const notificationExists = this.notifications.some(notification => notification.content === notificationContent);

        if (!notificationExists) {
          this.notifications.push({
            type: 'warning',
            content: notificationContent
          });
        }
      });

      this.updatingNotifications = false;
    });

    
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
   updateColor = () => {
   var navbar = document.getElementsByClassName('navbar')[0];
     if (window.innerWidth < 993 && !this.isCollapsed) {
       navbar.classList.add('bg-white');
       navbar.classList.remove('navbar-transparent');
     } else {
       navbar.classList.remove('bg-white');
       navbar.classList.add('navbar-transparent');
     }
   };
  ngOnInit() {
    
    this.api.checkOrders().subscribe((data: any[]) => { 
      if (data.length !== 0) {
        const orderCode = data[0].OrderCode;
        const notificationContent = `<a href="/#/orders-edit/${orderCode}">La orden ${orderCode} está en espera de aprobación de crédito </a>`;
  
        // Verifica si la notificación ya existe antes de agregarla
        const notificationExists = this.notifications.some(notification => notification.content === notificationContent);
  
        if (!notificationExists) {
          this.notifications.push({
            type: 'warning',
            content: notificationContent
          });
        }
      }
    });
   /*  this.webSocketService.receiveMessage().subscribe(
      (message) => console.log('Mensaje recibido:', message),
      (error) => console.error('Error en WebSocket:', error),
      () => console.log('Conexión cerrada')
    );
 */
  
    //this.webSocketService.sendMessage('Hola desde Angular');


    //window.addEventListener("resize", this.updateColor);


    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe(event => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }

  actualizarNotificaciones() {    
    if (this.notificationPanelOpen == true) {
      this.notifications = [];
      this.updatingNotifications = true;

      this.api.notificationInbox.subscribe((data:any) => {
        this.notifications.push(data);
      });
      this.api.notificationUpdate.subscribe((data:any) => {
        let current_i = -1;
        for (let i = 0; i < this.notifications.length; i++) {
          if (this.notifications[i].id == data.id) {
            this.notifications.splice(i,1);
            break;
          }
        }
        this.notifications.push(data);
      });
  
      this.api.checkOrders().subscribe((data: any[]) => { 
        data.forEach(order => {
          const orderCode = order.OrderCode;
          const FolioNum = order.FolioNum;
          const notificationContent = `<a href="/#/orders-edit/${orderCode}">La orden ${FolioNum} está en espera de aprobación de crédito </a>`;
  
          // Verifica si la notificación ya existe antes de agregarla
          const notificationExists = this.notifications.some(notification => notification.content === notificationContent);
  
          if (!notificationExists) {
            this.notifications.push({
              type: 'warning',
              content: notificationContent
            });
          }
        });
  
        this.updatingNotifications = false;
      });
    }
  }
  

  notifyPanel() {
    this.notificationPanelOpen = !this.notificationPanelOpen;
  }
  dropNotify(i:number) {
    this.notifications.splice(i,1);
  }
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    const html = document.getElementsByTagName("html")[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }

    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function() {
        mainPanel.style.position = "";
      }, 500);
    }
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const html = document.getElementsByTagName('html')[0];
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const html = document.getElementsByTagName("html")[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      html.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function() {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function() {
        $toggle.classList.add("toggled");
      }, 430);

      var $layer = document.createElement("div");
      $layer.setAttribute("class", "close-layer");

      if (html.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (html.classList.contains("off-canvas-sidebar")) {
        document
          .getElementsByClassName("wrapper-full-page")[0]
          .appendChild($layer);
      }

      setTimeout(function() {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function() {
        //asign a function
        html.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function() {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      html.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  open(content) {
    this.modalService.open(content, {windowClass: 'modal-search'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  ngOnDestroy(){
     //window.removeEventListener("resize", this.updateColor);
  }
}
