import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mdl-add-transport',
  templateUrl: './mdl-add-transport.component.html',
  styleUrls: ['./mdl-add-transport.component.scss']
})
export class MdlAddTransportComponent implements OnInit {
 
  
  public transport: any = {
    Name:'',
    Plate: '',
    Brand:'',
    Model:''
  };



  constructor(private api: ApiService, public activeModal: NgbActiveModal) {
   
   }

  ngOnInit(): void {
  }

/*   validateRut(rut:string) {
    this.api.showLoading();
    this.api.checkNotExistsRut(rut).subscribe((data:any) => {
      this.api.hideLoading();
      this.customer.CardCode = data.CardCode;
      this.customer.LicTradNum = data.LicTradNum;
      this.address.CardCode = this.customer.CardCode;
      this.contact.CardCode = this.customer.CardCode;
    }, (err:any) => {
      this.api.hideLoading();
      this.api.showToastError(err.error.error);
      this.customer.LicTradNum = '';
      this.customer.CardCode = '';
    });
  } */

  save() {
    this.api.showLoading();
    let transport = JSON.parse(JSON.stringify(this.transport));

    this.api.manageTransport(transport).subscribe((data:any) => {
      this.api.hideLoading();
      this.api.showOk("Cambios guardados correctamente").then(() => { this.activeModal.close();});
    }, (err:any) => {
      this.api.hideLoading();
      this.api.showError(err.error.error);
    }); 

  }

  closeModal(){
    this.activeModal.close();
  }

}
